import styled from 'styled-components';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import { nuDSColor } from '@nubank/nuds-web/styles/themeUtils';

const StyledTypography = styled(Typography)`
  &.active,
  &:hover {
    cursor: pointer;
    color: ${nuDSColor('black', 'default')};
  }
`;

StyledTypography.displayName = 'StyledTypography';

export default StyledTypography;
