import React, { useState, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import Grid from '@nubank/nuds-web/components/Grid/Grid';
import Box from '@nubank/nuds-web/components/Box/Box';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import { NuDsContext } from '@nubank/nuds-web/components/NuDSProvider/NuDSProvider';
import Link from '@nubank/nuds-web/components/Link/Link';

import generateDPRsForCMSImage from '@nubank/www-latam-commons/utils/cms/images/generateDPRsForCMSImage';
import Image from '@nubank/www-latam-commons/components/Image/Image';

import { StyledChip } from '../../../../components/ui/MenuWrapper/styles/StyledChip';

import { itens } from './Itens';
import StyledTypography from './styles/StyledTypography';

const generateTabs = () => {
  itens.map(product => ({
    id: product.id,
    title: product.title,
    chipText: product.chipText,
  }));

  return itens;
};

function SectionProducts({ index }) {
  const { formatMessage } = useContext(NuDsContext);
  const tabs = useMemo(() => generateTabs(formatMessage, index), []);
  const [visibleTab, setVisibleTab] = useState(tabs[0].id);

  const imageSrcSet = generateDPRsForCMSImage([{
    imageMobile: { url: 'https://nubank.com.br/images-cms/1712672906-woman_eating_cherry-mobile.jpg' },
    imageTablet: { url: 'https://nubank.com.br/images-cms/1712672906-woman_eating_cherry-mobile.jpg' },
    imageDesktop: { url: 'https://nubank.com.br/images-cms/1712672906-woman_eating_cherry-mobile.jpg' },
  }]);

  const listOfButtons = tabs.map(item => (
    <Box
      key={`list-buttons-${item.id}`}
      marginVertical={{ xs: '8x', md: '6x' }}
      paddingHorizontal={{ xs: '8x', md: '0' }}
      onClick={() => setVisibleTab(item.id)}
    >
      {item.chipText && (
        <Typography textAlign={{ xs: 'center', lg: 'left' }}>
          <StyledChip
            backgroundColor="primary.defaultL10"
            textColor="primary.default"
            text={item.chipText}
          />
        </Typography>
      )}
      <StyledTypography
        variant="heading2"
        textAlign={{ xs: 'center', lg: 'left' }}
        color={visibleTab === item.id ? 'black.light' : 'black.defaultT20'}
      >
        {item.title}
      </StyledTypography>
      <Box marginVertical="4x" display={visibleTab === item.id ? '' : 'none'}>
        <Typography
          textAlign={{ xs: 'center', lg: 'left' }}
          marginBottom="20px"
        >
          {item.content}
        </Typography>
        <Typography
          textAlign={{ xs: 'center', lg: 'left' }}
        >
          <Link
            color="primary"
            href={item.ctaHref}
            target="_blank"
            variant="action"
          >
            {item.ctaText}
          </Link>
        </Typography>
      </Box>
    </Box>
  ));

  return (
    <Box
      tag="section"
      backgroundColor="white"
      className="SectionProducts"
      paddingVertical={{ xs: '12x', md: '16x', lg: '24x' }}
    >
      <Grid>
        <Grid.Child
          gridColumn={{ xs: '1 / -1', md: '2 / 6', lg: '2 / span 4' }}
        >
          <Box>
            <Image
              loading="lazy"
              alt="Homen segurando o celular"
              srcSet={imageSrcSet}
            />
          </Box>
        </Grid.Child>
        <Grid.Child
          gridColumn={{ xs: '1 / -1', lg: '7 / span 5' }}
        >
          <Typography
            textAlign={{ xs: 'center', lg: 'left' }}
            marginTop="20px"
          >
            <StyledChip
              backgroundColor="primary.defaultL10"
              textColor="primary.default"
              text="Novo"
            />
          </Typography>
          {listOfButtons}
        </Grid.Child>
      </Grid>
    </Box>
  );
}

SectionProducts.propTypes = {
  index: PropTypes.number.isRequired,
};

export default React.memo(SectionProducts);
