export const itens = [
  {
    id: '12',
    title: 'Boleto no Crédito',
    chipText: '',
    ctaHref: 'https://blog.nubank.com.br/como-pagar-boletos-com-o-cartao-de-credito-nubank/?utm_source=website&utm_medium=blog&utm_campaign=cp-portfolio&utm_content=n-possibilidades____leia&utm_term=dg__pub__brand__all__06062023',
    ctaText: 'Saiba mais',
    content: 'Pague ou parcele seus boletos com o cartão de crédito e tenha mais controle sobre o seu dinheiro. Antecipe as parcelas e receba desconto proporcional aos juros',
  },
  {
    id: '13',
    title: 'Pix no Crédito',
    chipText: '',
    ctaHref: 'https://blog.nubank.com.br/pix-no-credito-no-nubank-tudo-sobre/?utm_source=website&utm_medium=blog&utm_campaign=cp-portfolio&utm_content=n-possibilidades____leia&utm_term=dg__pub__brand__all__06062023',
    ctaText: 'Saiba mais',
    content: 'Faça Pix no cartão de crédito podendo parcelar o valor em até 12 vezes. Você pode usar uma chave Pix ou QR Code. Simule na hora as parcelas e as taxas que serão cobradas antes de realizar a transação',
  },
  {
    id: '14',
    title: 'Parcelamento de Compras',
    chipText: '',
    ctaHref: 'https://blog.nubank.com.br/quando-vale-a-pena-usar-parcelamento-de-compras-especificas-nubank/?utm_source=website&utm_medium=blog&utm_campaign=cp-portfolio&utm_content=n-possibilidades____leia&utm_term=dg__pub__brand__all__06062023',
    ctaText: 'Saiba mais',
    content: 'Parcele suas compras de estabelecimentos físicos e virtuais direto no app do Nubank. Você escolhe a quantidade de parcelas e pode antecipar o pagamento sempre que quiser.',
  },
];
